import React from 'react';
import ReactHTMLParser from 'react-html-parser';
import { StaticImage } from 'gatsby-plugin-image';
import { Link } from '@latitude/link';
import { BUTTON_STYLE } from '@latitude/core/utils/constants';
import { Heading4, Heading6 } from '@latitude/heading';
import Text from '@latitude/text/Text';
import ListNumbered from '@latitude/list-numbered/ListNumbered';
import BrandedCallout from '@latitude/callout/BrandedCallout';
import FramedBrandBanner from '@/components/FramedBrandBanner/FramedBrandBanner';
import Layout from '../components/layout';
import Section from '../components/Section/Section';
import PageData from '../data/pages/determine-rate.json';
import { COLOR, PADDING } from '../utils/constants';
import ConfettiFramedContainer from '../components/ConfettiFramedContainer/ConfettiFramedContainer';
import { Box } from '../components/Box/Box';
import Lframe from '../components/Lframe/Lframe';
import HeroBranded from '../components/HeroBranded/HeroBranded';
import { List, ListItem } from '../components/List';
import { parseContent } from '../utils/helpers';
import Metadata from '../components/Metadata/Metadata';

const DetermineRatePage = props => (
  <Layout location={props.location}>
    <main className="navigation-spacer" css="background-color:#f8f8f8;">
      <Metadata
        title={PageData.metaTitle}
        description={PageData.metaDesc}
        canonical={PageData.path}
      />
      <Lframe determineRate />
      <HeroBranded
        bgConfetti
        title={PageData.title}
        text={PageData.desc}
        breadcrumbs={PageData.content.breadcrumbs}
      />

      <Section
        className="d-block d-sm-none bg-white"
        backgroundImage={require('../images/bg-confetti-mobile.svg')}
      />

      <div className="bg-white pt-5 pb-5">
        <div className="col-10 mx-auto text-center">
          <Heading4 className="text-center mb-0 pl-1 pr-1" color={COLOR.BLACK}>
            {PageData.content.info.title}
          </Heading4>
        </div>
        <div className="col-10 col-sm-10 col-md-8 col-lg-6 mx-auto text-center pt-2">
          <Text className="text-center" color={COLOR.BLACK}>
            {PageData.content.info.subtitle}
          </Text>
        </div>
        <ConfettiFramedContainer
          confettiBackgroundColor={COLOR.WHITE}
          minHeight="320px"
        >
          <Box
            backgroundColor={COLOR.GREY6}
            className="text-align-left "
            padding={`${PADDING.P24} ${PADDING.P16}`}
          >
            <ListNumbered
              alignItems="normal"
              counterBgColor="#0046AA"
              data={PageData.content.info.data}
              counterVerticalAlign
              separator="light"
            />
          </Box>
        </ConfettiFramedContainer>
      </div>

      <FramedBrandBanner
        className="pt-0"
        contentBgStyle="#e5e4fc"
        id="interest-rate-factors"
        framedContent={
          <StaticImage
            src="../images/girl-with-pug-square.png"
            alt="Girl with pug image"
            height={420}
            width={420}
          />
        }
        textContent={
          <>
            <Heading4 className="mb-4" color={COLOR.BLACK}>
              {PageData.content.interestRateFactors.title}
            </Heading4>
            <Text color={COLOR.BLACK} className="pb-5">
              {ReactHTMLParser(PageData.content.interestRateFactors.text)}
            </Text>
          </>
        }
      />

      <div id="how-to" className="pt-5 pb-5">
        <div className="col-10 mx-auto text-center">
          <Heading4 className="text-center mb-0" color={COLOR.BLACK}>
            {PageData.content.howTo.title}
          </Heading4>
        </div>
        <ConfettiFramedContainer
          framedContainerTheme={{
            frameColor: '#B1DBFF'
          }}
          confettiPosition="top"
          minHeight="320px"
          className="pb-0"
        >
          <Box
            backgroundColor={COLOR.BLUE_BABY}
            className="text-align-left "
            padding={PADDING.P24}
          >
            <Heading6 className="mb-3" color={COLOR.BLACK}>
              {PageData.content.howTo.subtitle}
            </Heading6>
            <List className="branded-list">
              {PageData.content.howTo.data.map((item, index) => (
                <ListItem key={index}>{parseContent(item) || ''}</ListItem>
              ))}
            </List>
            <div>
              <Text className="mt-2">
                Other criteria may apply depending on your situation.
              </Text>
            </div>
          </Box>
        </ConfettiFramedContainer>
      </div>

      <BrandedCallout
        line1={
          <React.Fragment>
            Need more information or help applying?
          </React.Fragment>
        }
        line2={
          <React.Fragment>
            <span>Mon to Fri: 8:30am - 6pm (AEST) except public holidays</span>
          </React.Fragment>
        }
        cta={
          <Box paddingTop={PADDING.P24} width="210px">
            <Link
              button={BUTTON_STYLE.SECONDARY_INVERSE}
              href="tel:132826"
              trackId="callout-call-btn"
            >
              Call 13 28 26
            </Link>
          </Box>
        }
      />
    </main>
  </Layout>
);

export default DetermineRatePage;
